.card {
    &__link {
        .button {
            margin-top: 1em;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
    }
}