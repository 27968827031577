@import "./navbar-animation.scss";

.navbar-top {
    padding: 0.75em 0 0.25em 0;
}

.navbar {
    background-color: white !important;
}

.navbar.is-transparent.headroom--top {
    background: transparent;

    .navbar-item {
        font-weight: bold;
    }
}

.navbar--has-border {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05) ;
}

@media screen and (max-width: $desktop) {
    .navbar-menu .navbar-item {
        color: black;
    }
}
