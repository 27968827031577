.content-block {
    &--image-gallery {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    &--code-snippet {
        pre {
            background: white;
            margin-top: 1em;
            margin-bottom: 1em;
        }
    }
}