html {
    // scroll-behavior: smooth;
    background-color: #f5f9fa;
}

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__main {
        flex: 1;

        .section:last-child {
            padding-bottom: 8em;
        }
    }
}

.layout.layout--rich-header {
    .layout__main {
        .section:first-child {
            margin-bottom: 2em;
        }
    }
}

.layout:not(.layout--rich-header) {
    .layout__main {
        .section:first-child {
            // margin-top: $navbar-height + 2rem;
        }
    }
}