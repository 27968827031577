.photo-card {
    min-height: 15em;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &__background, &__foreground {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }

    &__background {
       overflow: hidden;

       img {
           object-fit: cover;
           height: 100%;
           transition: transform 330ms ease-in-out;
       }
    }

    &__type, &__title {
        position: absolute;
        padding: 0.2em 0.5em;
    }

    &__type {
        background: $primary;
        color: white;
        top: 0;
        left: 0;
    }

    &__title {
        background: rgba(0, 0, 0, 0.2);
        color: white;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

a:hover .photo-card {
    img {
        transform: scale(1.2);
    }
}