$header-slider-cta-color: rgba(255, 255, 255, 0.8);

.header-slider {
    width: 100%;
    // margin-top: $navbar-height;
}

.header-slider-slide {
    position: relative;

    &__background, &__foreground  {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
    }

    &__background {
        object-fit: cover;
    }

    &__foreground {
        display: flex;
        // flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    &__text-wrapper {
        width: 100%;
    }

    &__cta, &__cta:hover, &__cta:visited, &__cta:active {
        padding: 0.5em 1em;
        border: solid 3px $header-slider-cta-color;
        border-radius: 15px;
        color: $header-slider-cta-color !important;
        font-size: 3rem;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.8);
    }

    @media screen and (max-width: 768px) {
        &__cta {
            font-size: 1.5rem;
        }
    }

    &__cta:hover {
        font-weight: bold;
    }
}

.header-slider-text__heading, .header-slider-text__tagline {
    display: inline-block;
    background: white;
}

.header-slider-text__heading {
    padding: 0rem 0.5rem;
    margin-bottom: 0.25rem;
    font-size: 3rem;
    font-weight: 600;

    @media screen and (max-width: 1024px) {
        font-size: 1.2rem;
    }
}

.header-slider-text__tagline {
    padding: 0.25rem 0.5rem;
    margin-bottom: 1em;
}

.header-slider-text__button {
    display: inline-block;
    margin-top: 1em;
}

.header-slider-slide__text-wrapper > .container {
    @media screen and (max-width: 768px) {
        margin-left: 4em;
        margin-right: 4em;
    }
}