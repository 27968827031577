.icons-and-title-list {
    list-style: none;
    
    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2em;
        
        img {
            width: 60px;
            margin-right: 1em;
        }
    }
}


.icons-and-title-list--icons-small {
    li {
        margin-bottom: 0em;
        
        img {
            width: 40px;
        }
    }
}