.crumbs {
    list-style: none;
    margin-bottom: 1em;
    
    li {
        display: inline;
    }
    
    li:not(:last-child):after {
        padding-left: 0.3em;
        content: '>'
    }
}