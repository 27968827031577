.news-event-item {
    margin-bottom: 3em;

    &__title {
        display: block;
        margin-top: 2em;
    }

    &__abstract {
        margin-top: 0.5em;
    }
}