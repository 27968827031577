.logo {
    display: flex;
    align-items: center;

    &__image {
        width: 50px;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        // border: solid white 10px;
        // border-radius: 5px;
    }

    &__text {
        font-size: 1.4rem;
        margin-left: 0.4em;
    }

    // @media screen and (max-width: $desktop - 1) {
    //     width: 75px;
    // }
}
