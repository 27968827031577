.explore-item {
    display: flex;
    flex-direction: row;
    margin-bottom: 3em;
    background: white;

    @media screen and (max-width: $tablet) {
        flex-direction: column;

        &__image {
            margin-left: 1em;
        }
    }

    &__image {
        width: 10em;
        flex: 0;
    }

    &__text {
        flex: 1;
        margin: 1em;
    }

    &__cta {
        display: block;
        margin-top: 1em;
    }

    &__image.photo-card {
        min-height: 0;
        width: 300px;
        height: 200px;
    }

    &__title {
        h2 {
            margin-bottom: 0.25em;
        }
    }
}