@charset "utf-8";

// @import "~@fortawesome/fontawesome-free/css/all.css";
@import "./fonts.scss";

// bulma variables

$family-sans-serif: "Fira Sans", sans-serif;
$family-primary: $family-sans-serif;

// colors

$turquoise: #6298a5;
$primary: $turquoise;
$link: $primary;
$grey-dark: #707070;

// navbar
$navbar-height: 7rem;
$navbar-item-img-max-height: none;
$navbar-item-color: white;
$footer-padding: 2em;

// typography
$text: $grey-dark;
$background: #f5f9fa;
$size-1: 2rem;
$size-2: 1.75rem;
$size-3: 1.5rem;
$size-4: 1.3rem;
$size-5: 1.15rem;

$column-gap: 2rem;

// Import all of Bulma
@import "~bulma/bulma";

// layouts
@import "./layouts/layout.scss";

// pages
@import "./page.scss";

// components
@import "./components/navbar.scss";
@import "./components/logo.scss";
@import "./components/footer.scss";
@import "./components/gallery.scss";
@import "./components/slider.scss";
@import "./components/map.scss";
// @import "./components/cookie-consent.scss";
@import "./components/content-blocks.scss";
@import "./components/parameter-page.scss";
@import "./components/header-slider.scss";
@import "./components/photo-card.scss";
@import "./components/card.scss";
@import "./components/crumbs.scss";
@import "./components/icons-and-title-list.scss";
@import "./components/news-event-item.scss";
@import "./components/explore-item.scss";
