.parameter-page {
    &__icon {
        img {
            width: 60px;
        }
    }

    &__section {
        margin-top: 2em;
        margin-bottom: 2em;
    }
}
