.footer {
    color: $white;
    background-color: $primary;

    a {
        font-weight: bold;
        color: $white;
    }

    &__address, &__newsletter, &__logo {
        padding-left: 1em;
        padding-right: 1em;
    }

    &__address {
        padding-top: 1.5em;

        @media screen and (max-width: $tablet - 1) {
            .separator {
                display: none;
            }

            > span {
                display: block;
                white-space: no-wrap;
            }
        }
    }

    &__logo {
        text-align: center;
        margin-top: 2em;

        img {
            width: 200px;
        }
    }
}

.footer-links a {
    display: inline-block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-right: 0.25em;
}
