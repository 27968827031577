/* put your fonts in src/fonts and your @font-faces here */

/*
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/open-sans-v17-latin-300.eot');
    src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans-v17-latin-300.eot?#iefix') format('embedded-opentype'),
    url('../fonts/open-sans-v17-latin-300.woff2') format('woff2'),
    url('../fonts/open-sans-v17-latin-300.woff') format('woff'),
    url('../fonts/open-sans-v17-latin-300.ttf') format('truetype'),
    url('../fonts/open-sans-v17-latin-300.svg#OpenSans') format('svg');
}
*/

/* fira-sans-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/fira-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/fira-sans-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-v16-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-v16-latin-300.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-300italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Fira Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/fira-sans-v16-latin-300italic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/fira-sans-v16-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-v16-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-v16-latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-v16-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-v16-latin-300italic.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/fira-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/fira-sans-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-v16-latin-regular.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
  /* fira-sans-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/fira-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/fira-sans-v16-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/fira-sans-v16-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/fira-sans-v16-latin-600.woff') format('woff'), /* Modern Browsers */
         url('../fonts/fira-sans-v16-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/fira-sans-v16-latin-600.svg#FiraSans') format('svg'); /* Legacy iOS */
  }
